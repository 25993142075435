










import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: {
    OSmsMessage: () =>
      import(
        "@/components/organisms/event/modules/alertModule/o-sms-message.vue"
      ),
  },

  setup() {
    return {};
  },
});
